export default {
  // App settings
  VERSION: 'v3.0.34',
  APP_NAME: 'DMATES.IO',
  GMAPS_API_TOKEN: process.env.REACT_APP_GMAPS_API_TOKEN,
  API_URL: process.env.REACT_APP_DISPATCH_API_URL,
  PRICING_URL: process.env.REACT_APP_PRICING_API_URL,
  WSS_URL: process.env.REACT_APP_WSS_URL,
  GQL_API_URL: process.env.REACT_APP_GQL_API_URL,
  GQL_SUBSCRIPTIONS_URL: process.env.REACT_APP_GQL_SUBSCRIPTIONS_URL,
  LOGROCKET_PROJECT: process.env.REACT_APP_LOGROCKET_PROJECT,
  ROLLBAR_ENV: process.env.REACT_APP_ROLLBAR_ENV,
  ROLLBAR_ACCESS_TOKEN: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  LOCALE: process.env.REACT_APP_LOCALE,
  VERSION_PATH: process.env.REACT_APP_API_VERSION_PATH,
  REACT_APP_GOOGLE: process.env.REACT_APP_GOOGLE,
  REACT_APP_ENV: process.env.REACT_APP_ENV
}
